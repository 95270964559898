@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --byte-grey: #929292;
  --byte-orange: #f4a41b;
  --byte-gold: #efc326;
  --shadow-green: rgb(32, 37, 32);
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

/* Default Styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Oswald", "sans-serif";
  color: whitesmoke;
  margin: 0;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}

@font-face {
  font-family: "Archivo";
  src: local("Archivo-Bold"), url(/static/media/Archivo-Bold.29ae2430.ttf);
}

