@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

/* Default Styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Oswald", "sans-serif";
  color: whitesmoke;
  margin: 0;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}

@font-face {
  font-family: "Archivo";
  src: local("Archivo-Bold"), url("assets/fonts/Archivo-Bold.ttf");
}
